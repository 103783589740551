import { loyaltyUouClick } from '@wix/bi-logger-loyalty-data/v2';
import { useStyles } from '@wix/tpa-settings/react';
import {
  Check as CheckIcon,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
  TikTok as TikTokIcon,
  Twitter as TwitterIcon,
} from '@wix/wix-ui-icons-common/on-stage';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Text, TextButton, TextButtonPriority } from 'wix-ui-tpa/cssVars';

import { SocialMediaChannel, SocialMediaSettings, SocialMediaSettingsChannel } from '../../../types/domain';
import { classes, st } from './SocialMediaRule.st.css';

const socialMediaIconMap: Record<SocialMediaChannel, React.ComponentType<React.SVGAttributes<SVGElement>>> = {
  [SocialMediaChannel.Facebook]: FacebookIcon,
  [SocialMediaChannel.X]: TwitterIcon,
  [SocialMediaChannel.Instagram]: InstagramIcon,
  [SocialMediaChannel.TikTok]: TikTokIcon,
  [SocialMediaChannel.LinkedIn]: LinkedInIcon,
};

const channelTitleKeyMap: Record<SocialMediaChannel, string> = {
  [SocialMediaChannel.Facebook]: 'app.social-media.facebook',
  [SocialMediaChannel.X]: 'app.social-media.x',
  [SocialMediaChannel.Instagram]: 'app.social-media.instagram',
  [SocialMediaChannel.TikTok]: 'app.social-media.tiktok',
  [SocialMediaChannel.LinkedIn]: 'app.social-media.linkedin',
};

interface SocialMediaRuleProps {
  socialMedia: SocialMediaSettings;
  isDetailsCard?: boolean;
  onFollowSocialMediaChannel(type: SocialMediaChannel): void;
  followedChannels: SocialMediaChannel[];
}

export const SocialMediaRule: React.FC<SocialMediaRuleProps> = ({
  isDetailsCard,
  socialMedia,
  followedChannels,
  onFollowSocialMediaChannel,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const bi = useBi();

  const showSocialMediaIcons = styles
    .getStylesForAllBreakpoints()
    .some((breakpointStyles) => breakpointStyles.booleans.showSocialMediaIcons);

  const handleFollowChannel = (channel: SocialMediaSettingsChannel, isFollowed: boolean) => {
    const { type, url } = channel;
    if (!isFollowed) {
      onFollowSocialMediaChannel(type);
    }

    bi.report(
      loyaltyUouClick({
        value: isFollowed ? channel.type : `${channel.type} - follow`,
        origin: isDetailsCard ? 'loyalty_page_details_card' : 'loyalty_page_tiers_card',
      }),
    );

    window.open(url, '_blank');
  };

  return (
    <div className={classes.root}>
      {socialMedia?.channels?.map((channel) => {
        const { type } = channel;
        const isFollowed = followedChannels.includes(type);
        const Icon = socialMediaIconMap[type];
        const title = t(channelTitleKeyMap[type]);

        return (
          <div key={type} className={st(classes.socialMediaItem, { isDetailsCard })}>
            {showSocialMediaIcons && <Icon className={classes.socialMediaIcon} />}
            <TextButton
              className={classes.socialMediaTitle}
              onClick={() => handleFollowChannel(channel, isFollowed)}
              priority={TextButtonPriority.link}
            >
              {title}
            </TextButton>
            {isFollowed ? (
              <span className={classes.followedContainer}>
                <CheckIcon className={classes.followedIcon} />
                <Text className={classes.followedText}>{t('app.social-media.follow')}</Text>
              </span>
            ) : (
              <TextButton
                onClick={() => handleFollowChannel(channel, isFollowed)}
                className={classes.followButton}
                priority={TextButtonPriority.primary}
              >
                {t('app.social-media.follow')}
              </TextButton>
            )}
          </div>
        );
      })}
    </div>
  );
};
